section.text-block {
  p {
    font-size: 14px;
    font-weight: 300;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  p, ul, ol, h2, h3, h4, h5, h6, pre, address {
    padding-bottom: 20px;
  }

  a {
    color: #75857C;
  }

  ol {
    display: inline-block;
    list-style-type: initial;
    text-align: left;
  }

  ul {
    display: inline-block;
    list-style-type: initial;
    text-align: left;
    padding-left: 1em;
  }
}