@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .team-accordion--open .team-accordion__button {
    @apply bg-brand-orange-light border-transparent opacity-100;
  }

  .team-accordion--open .team-accordion__button-image {
    @apply bg-brand-orange-light;
  }

  .team-accordion--open .team-accordion__content {
    @apply opacity-100 pointer-events-auto;
  }

  #search-autocomplete-results {
    @apply max-h-96 overflow-y-scroll bg-white;
  }
  .autocomplete-list {
    @apply divide-y divide-gray-200;
  }
  .autocomplete-title {
    @apply inline-block w-full text-lg font-medium p-6 pb-2 border-b border-gray-200;
  }
  .autocomplete-section {
    @apply divide-y divide-gray-200;
  }
  .autocomplete-result {
    @apply hover:text-black text-primary block px-6 py-2;
  }
  .nav-link {
    @apply hover:underline hover:text-primary transition;
  }
}
