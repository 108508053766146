@layer base {
  html {
    font-family: "Roboto", system-ui, sans-serif;
    font-size: 14px;
  }
}

#main-menu {
  position: relative;
  z-index: 100;
}

#main-menu .green-bar,
#main-menu .main-menu,
#main-menu .menu-items {
  width: 85px;
  transition: width 500ms ease;
  -moz-transition: width 500ms ease;
  -ms-transition: width 500ms ease;
  -o-transition: width 500ms ease;
  -webkit-transition: width 500ms ease;
}

#main-menu .menu-items {
  opacity: 0;
}

#main-menu .green-bar span,
#main-menu.mystyle .green-bar button {
  opacity: 1;
}

#main-menu.mystyle .green-bar span,
#main-menu .green-bar button {
  opacity: 0;
}

#main-menu.mystyle .menu-items {
  opacity: 1;
}

#main-menu .main-menu {
  width: 100px;
  background: #ffffff;
}

#main-menu.mystyle .green-bar {
  width: 100%;
}

#main-menu.mystyle .main-menu,
#main-menu.mystyle .menu-items {
  left: 0;
  width: 100%;
}

.main-menu-wrap .close-menu {
  position: relative;
}

@layer components {
  .main-menu-wrap .close-menu:before,
  .main-menu-wrap .close-menu:after {
    position: absolute;
    content: "";
    height: 50px;
    @apply bg-primary;
  }
}

.main-menu-wrap .close-menu:before {
  left: -100vw;
  right: 0;
  z-index: -1;
}

ul.sub-menu.level-3 {
  break-inside: avoid-column;
}

.gallery-top {
  height: 80%;
  width: 100%;
}

.gallery-thumbs {
  /*height: 20%;*/
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.checklist p {
  display: flex;
  align-items: center;
}

.checklist p span {
  margin-right: 0.5rem;
  color: #38b449;
}

.product_grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: "head" "thumb" "middle" "ldetail" "rdetail";
}

.product_grid .head {
  grid-area: head;
}

.product_grid .thumb {
  grid-area: thumb;
}

@media (max-width: 1279px) {
  .product_grid .middle {
    grid-area: middle;
  }
}

.product_grid .ldetail {
  grid-area: ldetail;
}

.product_grid .rdetail {
  grid-area: rdetail;
}

@media (min-width: 1280px) {
  .product_grid {
    grid-template-columns: repeat(2, 48.92%);
    grid-template-areas:
      "thumb head"
      "thumb middle"
      "ldetail rdetail";
  }
}

.accordionWrapper {
  float: left;
}

.accordionItem {
  float: left;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.accordionItemHeading {
  cursor: pointer; /*margin:0px 0px 10px 0px;*/
  padding: 10px 0;
}

.close .accordionItemContent {
  height: 0px;
  transition: height 1s ease-out;
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  float: left;
  display: block;
}

.accordionItem i {
  transition: all 0.3s linear;
}

.open i {
  transform: rotate(180deg);
}

.open .accordionItemContent {
  width: 100%;
  display: block;
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: top;
  -o-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition: -webkit-transform 0.4s ease-out;
  -o-transition: -o-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease;
  box-sizing: border-box;
}

.open .accordionItemHeading {
  margin: 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

@layer components {
  input:checked + .slider {
    @apply bg-primary;
  }
}
input:focus + .slider {
  box-shadow: 0 0 1px #7c8478;
}

input:checked + .slider:before {
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tabs-allowed .tabs-trigger {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border-bottom: none;
}

.tabs-trigger.is-selected,
.tabs-trigger.is-selected:hover,
.tabs-trigger.is-selected:focus {
  color: #ffffff;
  background-color: #000;
  border-bottom: none;
}

.tabs-panel .content {
  margin-top: 20px;
  padding: 0;
}

.accordion-trigger {
  padding: 10px 0;
}

.tabs-panel:not(:first-of-type) {
  border-top: none;
}

.tabs-panel .content.is-open {
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
}

.accordion-trigger[aria-expanded="false"] i {
  transition: all 0.3s linear;
}

.accordion-trigger[aria-expanded="true"] i {
  transition: all 0.3s linear;
  transform: rotate(90deg);
}

.tooltip-custom {
  position: relative;
  text-decoration: underline dotted;
  cursor: help;
}

.tooltip-custom::before,
.tooltip-custom::after {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.tooltip-custom:hover::before,
.tooltip-custom:hover::after {
  opacity: 1;
  visibility: visible;
}

.tooltip-custom::before {
  content: attr(data-tooltip);
  z-index: 2;
  width: 150px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  padding: 5px;
}

.tooltip-custom::after {
  content: "";
  width: 0;
  height: 0;
}

.tooltip--top::before,
.tooltip--top::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 6px;
}

.tooltip--top::after {
  margin-bottom: 1px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.9);
}

.tooltip--bottom::before,
.tooltip--bottom::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  margin-top: -1px;
}

.tooltip--bottom::after {
  margin-top: -8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.9);
}

.tooltip--right::before,
.tooltip--right::after {
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  margin-left: 6px;
}

.tooltip--right::after {
  margin-left: 1px;
  border-top: 5px solid transparent;
  border-right: 7px solid rgba(0, 0, 0, 0.9);
  border-bottom: 5px solid transparent;
}

.tooltip--left::before,
.tooltip--left::after {
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
  margin-right: 6px;
}

.tooltip--left::after {
  margin-right: 1px;
  border-top: 5px solid transparent;
  border-left: 7px solid rgba(0, 0, 0, 0.9);
  border-bottom: 5px solid transparent;
}

/*.teamAccordion .accordionItem.open{
  height: 570px
}
.teamAccordion .accordionItemContent{
  height: 390px;
  overflow-y: scroll;
}*/
.teamAccordion .accordionItem.close .close-btn {
  visibility: hidden;
  /*transition: all .3s linear;*/
}

.teamAccordion .accordionItem.open .close-btn {
  visibility: visible;
}

header {
  transition: all 0.3s linear;
}

header + main,
header + .home-slider {
  padding-top: 81px;
}

@media (min-width: 768px) {
  header + .home-slider {
    padding-top: 157px;
  }

  header + main {
    padding-top: 175px;
  }
}

header.home {
  box-shadow: none;
}

.sticky,
header {
  position: fixed;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  top: 0;
  left: 0;
}

.desktop-logo + img {
  height: 60px;
}

.swiper-button-disabled {
  display: none;
}

@media (min-width: 768px) {
  /* .ref-details .swiper-button-disabled{
      display: block;
      cursor: not-allowed;
    }*/
  .ref-details .reference-gallery-thumbs .swiper-wrapper {
    flex-direction: row;
  }

  .ref-thumbs-wrap .swiper-button-prev,
  .ref-thumbs-wrap .swiper-button-next {
    /*width: calc(100% - 2rem);*/
  }
}

.reference-gallery-thumbs .swiper-slide img {
  transition: 0.3s;
}

.tabs-panel .content.is-open {
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
  width: 100%;
  /* margin: 0px 0px 10px 0px; */
  display: block;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.4s ease;
  box-sizing: border-box;
  height: auto;
}

.tabs-panel .content {
  margin-top: 14px;
  padding: 0;
  transition: transform 1s ease-in-out;
  height: 0px;
  transition: height 1s ease-out;
  transform: scaleY(0);
  float: left;
  display: block;
}

.tabs-panel.is-hidden .content {
  display: block;
}

body.search-open {
  overflow: hidden;
}

.search-box.search-elem {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Higher than header */
  z-index: 1001;
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.search-box.search-elem .inner {
  width: 70%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.search-box.search-elem label {
  color: white;
  font-weight: 300;
}

.search-box.search-elem button.submit {
  outline: none;
  position: absolute;
  top: 0;
  right: 15px;
  height: 70px;
  padding: 10px 2rem;
  background: #00a896;
  font-size: 1rem;
  color: white;
}

.search-box.search-elem button.submit[disabled] {
  background: #ccc;
  color: #32213a;
}

.search-box.search-elem input[type="text"] {
  padding: 20px;
  height: 72px;
  font-size: 32px;
  font-weight: 300;
  border: none;
  border-bottom: solid 2px #999;
  transition: border 0.3s;
  border-radius: 0;
}

.search-box.search-elem input[type="text"]:focus {
  border-bottom: solid 2px #00a896;
  box-shadow: none;
}

.search-box.search-elem label.placeholder {
  position: absolute;
  top: 10px;
  left: 2rem;
  font-size: 32px;
  font-weight: 300;
  color: #999;
  transition: all 0.3s;
}

.search-box.search-elem label.placeholder.move-up {
  top: -25px;
  color: white;
  font-size: 1rem;
}

.search-box.search-elem.search-open {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.slider-indicator .bg-white {
  width: 20px;
}

@media (min-width: 768px) {
  .slider-indicator .bg-white {
    width: 85px;
  }
}

/*@media(min-width: 1280px){
  .slider-indicator .bg-white{
    width: 65px;
  }
}*/
.slider-indicator .bg-white:before {
  position: absolute;
  content: "";
  left: -100vw;
  top: 0;
  bottom: 0;
  right: 100%;
  background: #ffffff;
}

.menu-tab a,
.menu-desktop-tab a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.menu-tab .menu-open,
.menu-desktop-tab .menu-open {
  color: #fff;
  cursor: pointer;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.menu-tab .menu-close,
.menu-desktop-tab .menu-close {
  color: #fff;
  display: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .desktop-menu .menu-desktop-tab {
    /*left: 11px;*/
    left: 8px;
    padding: 14px 15px;
    height: 55px;
  }
  .desktop-menu .menu-hide {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1024px) {
  .desktop-menu .menu-desktop-tab {
    /*left: 20px;*/
    left: 17px;
    padding: 14px 25px;
  }
  .desktop-menu .menu-hide {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1280px) {
  .desktop-menu .menu-desktop-tab {
    /*left: 20px;*/
    left: 16px;
    padding: 14px 20px 14px 0;
  }
  .desktop-menu .menu-hide {
    padding-left: 0;
    padding-right: 0;
  }
}

@layer components {
  @media (min-width: 768px) {
    .desktop-menu .menu-desktop-tab {
      position: absolute;
      z-index: 100;
      left: 0;
      top: 15px;
      transition: all 0.3s linear;
      width: 85px;

      @apply bg-primary;
    }
    .desktop-menu .menu-desktop-tab:before {
      content: "";
      position: absolute;
      left: -100vw;
      right: 100%;
      top: 0;
      bottom: 0;

      @apply bg-primary;
    }
  }
}

@media (min-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .desktop-menu {
    max-width: 1280px !important;
  }

  .desktop-menu {
    display: block;
  }
  .sticky .desktop-menu .menu-desktop-tab {
    top: -36px;
  }

  .desktop-menu.active .menu-desktop-tab .menu-open {
    display: none;
  }

  .desktop-menu.active .menu-nav li a {
    border-bottom: 1px solid transparent;
    transition: all 0.3s linear;
    font-size: 14px;
  }

  .desktop-menu.active .menu-nav li a:hover,
  .desktop-menu.active .menu-nav li.active a {
    border-bottom: 1px solid #ffffff;
  }

  .desktop-menu.active .menu-desktop-tab .menu-close {
    display: block;
  }

  .desktop-menu .menu-desktop-tab .menu-content {
    display: flex;
    flex-wrap: nowrap;
    line-height: 32px;
    /*justify-content: flex-end;*/
  }

  .desktop-menu.active .menu-desktop-tab .menu-content {
    /*justify-content: space-between;*/
  }

  .desktop-menu .menu-desktop-tab ul.menu-nav > li {
    margin-right: 40px;
    width: max-content;
  }

  .desktop-menu .menu-desktop-tab .menu-nav {
    display: none;
  }

  .desktop-menu.active .menu-desktop-tab .menu-nav {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  /*end of menu-tab*/
  .desktop-menu.active .menu-desktop-tab {
    left: 20px;
    right: 20px;
    transition: all 0.4s linear;
    width: calc(100% - 40px);
  }

  .desktop-menu .menu-hide {
    width: calc(100% - 40px);
    padding: 50px 0;
    position: absolute;
    z-index: 10;
    top: 40px;
    left: 20px;
    transition: all 0.3s linear;
    transform: translateX(-100vw);
    background-color: #f0f0f0;
    /*end of nav*/
  }

  .sticky .desktop-menu .menu-hide {
    top: 0;
  }

  .desktop-menu .menu-hide:before {
    content: "";
    position: absolute;
    left: -100vw;
    right: 100%;
    top: 0;
    bottom: 0;
    background-color: #f0f0f0;
  }

  .desktop-menu.active .menu-hide {
    transition: all 0.3s linear;
    transform: translateX(0);
  }

  .desktop-menu .menu-hide nav > ul li.show {
    display: block;
  }

  .desktop-sub-menu {
    display: none;
  }
  .desktop-sub-menu.show {
    display: block;
  }
}

/*
 Disgusting solution for now.
 Give menu both classes desktop-menu and menu (mobile-menu).
 Just apply different style on different screen sizes
  */
@media (max-width: 767px) {
  .menu .menu-tab .menu-close {
    display: block;
  }

  .menu .menu-tab {
    width: 90vw;
    position: fixed;
    z-index: 100;
    right: -100%;
    cursor: pointer;
    transition: all 0.3s linear;
    padding: 10px 20px;
    @apply bg-primary;
    /*end of div*/
  }

  /*end of menu-tab*/
  .menu.active .menu-tab {
    right: 0;
    transition: all 0.3s linear;
  }

  .menu .menu-hide {
    width: 90vw;
    height: 90vh;
    right: -100%;
    position: fixed;
    z-index: 10;
    transition: all 0.3s linear;
    /*end of nav*/
  }

  .menu .menu-hide nav {
    /*end of ul*/
  }

  .menu .menu-hide nav ul {
    /*end of li*/
  }

  .menu .menu-hide nav ul li {
    list-style-type: none;
    transition: all 0.3s linear;
  }

  .menu.menu-hide nav ul li:hover {
    background-color: #e74c3c;
    transition: all 0.5s ease;
  }

  /*end of menu-hide*/
  .menu.active .menu-hide {
    right: 0px;
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.3s linear;
  }

  /* mobile menu style */
  /* HEADER STYLES
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .menu .menu-wrapper {
    display: none;
  }

  .menu.active .menu-wrapper {
    display: block;
  }

  .menu .list-wrapper {
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    background: #fff;
  }

  .menu .list-wrapper:nth-child(2),
  .menu .list-wrapper:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    backface-visibility: hidden;
    transition: transform 0.5s;
  }

  .menu .list-wrapper:nth-child(2).is-visible,
  .menu .list-wrapper:nth-child(3).is-visible {
    transform: none;
  }

  .menu .list-wrapper:nth-child(1) > ul > li > .sub-menu,
  .menu .list-wrapper:nth-child(2) .level-3 {
    display: none;
  }

  .menu .menu-wrapper li + li {
    /* margin-top: 20px; */
  }

  .menu .menu-wrapper a {
    display: block;
    position: relative;
    margin: 5px 0;
    border-bottom: 1px solid transparent;
    transition: all 0.3s linear;
  }

  .menu .menu-wrapper a:hover,
  .menu .menu-wrapper a.is-active {
    border-bottom: 1px solid #000000;
  }

  .menu .menu-wrapper a.nested::before {
    content: "\f054";
    font-family: "Font Awesome 6 Free";
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: var(--orange);
  }

  .menu .back-one-level {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
  }

  .menu .back-one-level span {
    margin-left: 20px;
  }

  .back-one-level span + span {
    font-size: 0;
  }
}

/* MAIN STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-main {
  max-width: 600px;
  width: 100%;
  padding: 0 15px;
  margin: 100px auto 0;
}

/* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 60px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 5px;
  background: rgba(255, 255, 255, 0.65);
}

.page-footer a {
  display: flex;
  margin-left: 9px;
}

.teamAccordion .grid .hidden {
  min-width: 200px;
}

.teamAccordion .font-medium svg {
  width: 1rem;
  height: 1rem;
}

footer p a i {
  width: 16px;
}
