@import "../../node_modules/swiper/dist/css/swiper.min.css";
@import "./fontawesomecustom.css";
@import "../vendor/css/accordion-tabs.css";
@import "../vendor/css/jquery.fancybox.css";
@import "../vendor/css/photoswipe.css";
@import "./tailwind.css";
@import "./slicingarts.css";
@import "./moodboard.scss";
@import "./content-blocks/text.scss";
@import "./content-blocks/expert.scss";

.reference-applied-products > p,
.reference-applied-products > ul {
  padding-bottom: 20px;
}
.reference-applied-products li {
  margin-left: 12px;
  list-style-type: disc;
}
/*input[type="search"]:focus::placeholder {*/
/*    color: transparent;*/
/*}*/
.tooltip-custom::before {
  width: 200px;
}

.reference-call-link a {
  color: rgb(254 213 2 / var(--tw-text-opacity));
}

.screen-reader-only {
  clip: rect(0 0 0 0);
  border: 0;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.desktop-logo {
  width: 181px;
  height: 50px;
}

@media (min-width: 768px) {
  .desktop-logo {
    width: 300px;
    height: 83px;
  }
  .sticky .logo .desktop-logo {
    width: 0;
    height: 0;
  }
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

