/*
 * THIS IS A CUSTOM FILE
 * TO ADD AN ICON:
 * Go to /var/www/websites/deprojectinrichter/assets/fontawesome-free-6.2.0-web/css/all.css
 * and get the css selector (example below) with the unicode and paste it here.
 *
 *  .fa-chevron-left::before {
 *    content: "\f053";
 *  }
 *
 * This file is included in main.css
 */

:root,
:host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
}

.fa {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular {
  font-family: "Font Awesome 6 Free";
}

.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
}

/* Icons */

.fa-envelope::before {
  content: "\f0e0";
}

.fa-phone::before {
  content: "\f095";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-times::before {
  content: "\f00d";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-file-zipper::before {
  content: "\f1c6";
}
.fa-file-powerpoint::before {
  content: "\f1c4";
}
.fa-file-pdf::before {
  content: "\f1c1";
}
.fa-file-image::before {
  content: "\f1c5";
}
.fa-file-lines::before {
  content: "\f15c";
}

/* Webfonts */

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"),
    url("../webfonts/fa-brands-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"),
    url("../webfonts/fa-solid-900.ttf") format("truetype");
}
